import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
    }
  },
  beforeMount() {
  },
  mounted() {

  },
  methods: {
  },
  template: `
        <span>
        <span v-if="this.params.data.ams === 'Yes' " style="color:green"><font-awesome-icon :icon="['far', 'check-circle']" size="lg"/></span>
        <span v-if="this.params.data.ams !== 'Yes' " style="color:red"><font-awesome-icon :icon="['far', 'times-circle']" size="lg"/></span>
        
        </span>
    `,
})
