import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
    }
  },
  beforeMount() {
  },
  mounted() {

  },
  methods: {
  },
  template: `
        <span>
        <span v-if="this.params.data.preAlertMet === 'Yes' " style="color:green"><font-awesome-icon :icon="['far', 'check-circle']" size="lg"/></span>
        <span v-if="this.params.data.preAlertMet === 'No' " style="color:red"><font-awesome-icon :icon="['far', 'times-circle']" size="lg"/></span>
        <span v-if="this.params.data.preAlertMet === 'Partial' " style="color:gold"><font-awesome-icon :icon="['fas', 'exclamation-triangle']" size="lg"/></span>
        
        </span>
    `,
})
