<template>
  <div>
    <b-card>
      <AgGrid
        ref="ShipmentGrid"
        :grid="aggrid"
        @LoadAll="LoadAll"
      >
        <template v-slot:lftbtn>
          <b-button
            v-if="((Role != 'readonly' && Role != 'readonly admin') && (Permission.CreateShipment)) || (Role == 'company admin')"
            v-b-tooltip.hover="'Create a New entry'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="CreateNewShipment()"
          ><font-awesome-icon
             :icon="['far', 'plus-square']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">New Shipment</span></b-button>
        </template>

      </AgGrid>
    </b-card>

    <b-modal
      id="CreateShipmentModal"
      ref="CreateShipment"
      title="New Shipment"
      size="lg"
      centered
      no-close-on-backdrop
    >
      <validation-observer
        ref="headerRules"
        tag="form"
      >
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="MBL"
                rules="max:50|required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>MBL #:</b></i></label>
                  <b-form-input
                    v-model="ShipmentDetail.MBL"
                    :state="errors.length > 0 ? false:null"
                    placeholder="MBL #"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Customer"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Customer:</b></i></label>
                  <v-select
                    v-model="ShipmentDetail.CustomerId"
                    :options="CustomerOptions"
                    label="name"
                    placeholder="Select a Customer"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="SaveNewShipment()"
          >
            <span class="align-middle">Save</span></b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from '@axios'

import { required } from '@validations'
import ViewBtnRenderer from '@core/layouts/ViewBtnRenderers/Booking/BookingShipmentViewBtnRenderer'
import AgGrid from '@/layouts/components/AgGrid.vue'
import ISFIcon from './ShipmentISFIcon'
import AMSIcon from './ShipmentAMSIcon'
import DocIcon from './ShipmentDocIcon'

export default {
  components: {
    AgGrid,
  },
  data() {
    return {
      required,
      ShipmentId: '',
      aggrid: [],
      CustomerOptions: [],
      ShipmentDetail: {
        CustomerId: '',
        MBL: '',
      },
      Permission: {},
      Role: '',
    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.BookingDbContext
    this.aggrid = this.InitGridInstance('Shipment')
    this.aggrid.columnDefs = [
      {
        headerName: '',
        field: 'ShipmentId',
        filter: false,
        sortable: false,
        autoHeight: true,
        width: 60,
        minWidth: 60,
        maxWidth: 60,
        suppressSizeToFit: true,
        suppressMovable: true,
        pinned: 'left',
        lockPinned: true,
        cellRenderer: 'ViewBtnRenderer',
        enableRowGroup: false,
        enablePivot: false,
      },
      {
        headerName: 'Status',
        field: 'status',
        minWidth: 100,
        // eslint-disable-next-line no-nested-ternary
        cellRenderer: params => (params.value === 'Approved' ? `<span style="color:green">${params.value}</span>` : params.value === 'Pending Review' ? `<span style="color:coral">${params.value}</span>` : params.value === 'Denied' ? `<span style="color:red">${params.value}</span>` : `${params.value}`),
      },
      {
        headerName: 'ISF',
        field: 'isf',
        minWidth: 75,
        filter: 'agSetColumnFilter',
        // cellRenderer: params => (params.value === 'Yes' ? `<span style="color:green">${params.value}</span>` : params.value === 'No' ? `<span style="color:red">${params.value}</span>` : `${params.value}`),
        cellRenderer: 'ISFIcon',
      },
      {
        headerName: 'AMS',
        field: 'ams',
        minWidth: 75,
        filter: 'agSetColumnFilter',
        // eslint-disable-next-line no-nested-ternary
        cellRenderer: 'AMSIcon',

      },
      {
        headerName: 'Pre-Alert',
        field: 'preAlertMet',
        minWidth: 85,
        filter: 'agSetColumnFilter',
        // eslint-disable-next-line no-nested-ternary
        cellRenderer: 'DocIcon',

      },
      {
        headerName: 'Customer', field: 'customerName', minWidth: 125, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'MBL #', field: 'mbl', minWidth: 155,
      },
      {
        headerName: 'Mode', field: 'shipmentMode', minWidth: 75, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Type', field: 'shipmentType', minWidth: 85, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Service', field: 'serviceType', minWidth: 85, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Consignee', field: 'consigneeName', minWidth: 150, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Shipper', field: 'sellerName', minWidth: 150, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Carrier', field: 'carrier', minWidth: 85, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'POL',
        marryChildren: true,
        children: [
          {
            headerName: 'POL', field: 'pol', maxWidth: 150,
          },
          {
            headerName: 'SI Cutoff',
            field: 'siCutoffDate',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            columnGroupShow: 'open',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
          },
          {
            headerName: 'CY Cutoff',
            field: 'cyCutoffDate',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            columnGroupShow: 'open',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
          },
        ],
      },
      {
        headerName: 'POD',
        marryChildren: true,
        children: [
          {
            headerName: 'POD', field: 'pod', maxWidth: 150,
          },
          {
            headerName: 'POD ETA',
            field: 'podeta',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            columnGroupShow: 'open',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
          },
          {
            headerName: 'POD ATA',
            field: 'podata',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            columnGroupShow: 'open',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
          },
          {
            headerName: 'Final Dest', field: 'finalDest', maxWidth: 150,
          },
          {
            headerName: 'Final Dest ETA',
            field: 'finalDestETA',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            columnGroupShow: 'open',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
          },
          {
            headerName: 'Final Dest ATA',
            field: 'finalDestATA',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            columnGroupShow: 'open',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
          },
        ],
      },
      {
        headerName: 'Feeder Vsl',
        marryChildren: true,
        children: [
          {
            headerName: 'Vessel',
            field: 'feederVesselName',
            autoHeight: true,
            wrapText: true,
            minWidth: 220,
            enableRowGroup: false,
            enablePivot: false,
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: {
              innerRenderer: params => `${params.data.feederVesselName || ''} | ${params.data.feederVoyage || ''}`,
            },
          },
          {
            headerName: 'ETD',
            field: 'feederETD',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
          },
          {
            headerName: 'ETA',
            field: 'feederETA',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
          },
          {
            headerName: 'VIA POL', field: 'feederVIAPOLName', columnGroupShow: 'open', minWidth: 100, filter: 'agSetColumnFilter',
          },
          {
            headerName: 'VIA POD', field: 'feederVIAPODName', columnGroupShow: 'open', minWidth: 100, filter: 'agSetColumnFilter',
          },
        ],
      },
      {
        headerName: 'Mother Vsl',
        marryChildren: true,
        children: [
          {
            headerName: 'Vessel',
            field: 'motherVesselName',
            autoHeight: true,
            wrapText: true,
            minWidth: 220,
            enableRowGroup: false,
            enablePivot: false,
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: {
              innerRenderer: params => `${params.data.motherVesselName || ''} | ${params.data.motherVoyage || ''}`,
            },
          },
          {
            headerName: 'ETD',
            field: 'motherETD',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
          },
          {
            headerName: 'ETA',
            field: 'motherETA',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
          },
          {
            headerName: 'VIA POL', field: 'motherVIAPOLName', columnGroupShow: 'open', minWidth: 100, filter: 'agSetColumnFilter',
          },
          {
            headerName: 'VIA POD', field: 'motherVIAPODName', columnGroupShow: 'open', minWidth: 100, filter: 'agSetColumnFilter',
          },
        ],
      },
      {
        headerName: 'CBM / Weight / Pallet',
        field: 'cbm',
        minWidth: 100,
        enableRowGroup: false,
        enablePivot: false,
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
          innerRenderer: params => `${params.value || '0'} / ${params.data.weight || '0'} / ${params.data.pallet || '0'}`,
        },
      },
      {
        headerName: 'Total Containers', field: 'totalContainers', minWidth: 100,
      },
      {
        headerName: 'Edit Log',
        marryChildren: true,
        children: [
          {
            headerName: 'Updated By', field: 'updatedBy', maxWidth: 134, minWidth: 100,
          },
          {
            headerName: 'Updated On',
            field: 'updatedOn',
            maxWidth: 158,
            minWidth: 100,
            enableRowGroup: false,
            enablePivot: false,
          },
          {
            headerName: 'Added By', columnGroupShow: 'open', field: 'createdBy', maxWidth: 134, minWidth: 100,
          },
          {
            headerName: 'Added On',
            columnGroupShow: 'open',
            field: 'createdOn',
            maxWidth: 158,
            minWidth: 100,
            enableRowGroup: false,
            enablePivot: false,
          },
        ],
      },
    ]
    this.aggrid.gridOptions.onSortChanged = () => { this.$refs.ShipmentGrid.saveState('Shipment') }
    this.aggrid.gridOptions.onColumnResized = () => { this.$refs.ShipmentGrid.saveState('Shipment') }
    this.aggrid.gridOptions.onColumnMoved = () => { this.$refs.ShipmentGrid.saveState('Shipment') }
    this.aggrid.gridOptions.onFilterChanged = () => { this.$refs.ShipmentGrid.saveState('Shipment') }

    this.aggrid.frameworkComponents = {
      ViewBtnRenderer,
      ISFIcon,
      AMSIcon,
      DocIcon,
    }
    this.LoadAll()
  },
  methods: {
    Open(ShipmentId) {
      this.ResetObject()
      this.ShipmentId = ShipmentId
      this.$router.push(`/booking/shipment/detail/${ShipmentId}`)
    },
    DoubleClickOpenPopup(params) {
      this.ResetObject()
      this.ShipmentId = params.data.shipmentId
      this.$router.push(`/booking/shipment/detail/${params.data.shipmentId}`)
    },
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadShipmentList(), this.LoadCustomerList()])
        .then(() => {
          // Get user role
          this.Role = this.GetUserRole()
          // Get permission
          this.Permission = this.GetUserPermission('Booking')
          this.LoadingEnd()
        })
    },
    LoadCustomerList() {
      // Loading Customer List
      return axios.get('/booking/customer/codes/all')
        .then(rowData => {
          this.CustomerOptions = rowData.data
        })
    },
    LoadShipmentList() {
      axios.get('/booking/shipment/active')
        .then(rowData => {
          this.aggrid.rowData = rowData.data
        }).then(() => {
          this.$refs.ShipmentGrid.ResetColumns()
        })
    },
    CreateNewShipment() {
      this.ResetObject()
      this.$refs.CreateShipment.show()
    },
    SaveNewShipment() {
      this.$refs.headerRules.validate().then(result => {
        if (result) {
          this.LoadingStart()
          const ShipmentModel = {
            CustomerId: this.ShipmentDetail.CustomerId.id,
            MBL: this.ShipmentDetail.MBL,
          }

          axios.post('/booking/shipment', ShipmentModel)
            .then(response => {
              this.LoadingEnd()
              this.$refs.CreateShipment.hide()
              this.DisplaySuccess('Shipment data has been saved.').then(() => {
                this.$router.push(`/booking/shipment/detail/${response.data}`)
              })
            })
            .catch(e => {
              this.LoadingEnd()
              this.$refs.CreateShipment.hide()
              this.DisplayError('New Shipment cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
            })
        } else {
          this.DisplayError('New Shipment cannot be saved.', 'Please check data requirements.')
        }
      })
    },
    ResetObject() {
      this.ShipmentDetail = {
        CustomerId: '',
        MBL: '',
        HBL: '',
      }
    },
  },

}
</script>
